



































import Vue from 'vue';
import { CurrentUser } from '@/store/users';
import { mdiLogout, mdiAccount } from '@mdi/js';
import { mapActions } from 'vuex';
import { SIGN_OUT } from '@/store/auth';

export default Vue.extend({
  name: 'UserPopUp',
  props:{
    connectedUser: {
      required:true,
      type: Object as () => CurrentUser,
      default(){ return {} as CurrentUser }
    },
  },
  data() {
    return {
      mdiAccount,
      mdiLogout
    };
  },
  methods: {
    ...mapActions({
      logout: SIGN_OUT,
    }),
    disconnect() {
      this.logout(null);
      this.$router.push({
        name: 'sign in',
        params: { lang: this.$i18n.locale },
      });
    },
  }
});
